import React from "react";
import pageImage from "images/HomepageLogo.png";
import { DocPageLayout, SEO, SearchResults } from "components";

const DocumentationBrowser = ({ location }) => {
  const title = "ECL Types Documentation";
  const description = "Emerald Cloud Lab Types Documentation Search";

  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get("term");

  return (
    <DocPageLayout headerText={"Documentation Center"}>
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      <SearchResults indexType="types" term={searchTerm} />
    </DocPageLayout>
  );
};

export default DocumentationBrowser;
